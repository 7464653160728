import { observable, makeObservable, IObservableArray, computed } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';
import ReportingRequirementOptionModel from '../companies/ReportingRequirementOptionModel';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import DropdownItem from '../forms/DropdownItem';

class HotelBookingReportingRequirementModel extends EntityFormModel {
  constructor() {
    super();
    makeObservable(this);
    this.Options = observable.array(new Array<ReportingRequirementOptionModel>());
  }
  public SetData(dbData: any): void {
    super.SetData(dbData, ['Options']);
    arraySetData(this.Options, ReportingRequirementOptionModel, dbData.Options);
  }

  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public Description: string;
  @observable
  // @ts-expect-error added by automation
  public Example: string;
  @observable
  // @ts-expect-error added by automation
  public Comment: string;
  @observable
  // @ts-expect-error added by automation
  public UDID: string;
  @observable
  // @ts-expect-error added by automation
  public Value: string;
  @observable
  // @ts-expect-error added by automation
  public Required: boolean;
  @observable
  // @ts-expect-error added by automation
  public Mandatory: boolean;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;
  @observable
  // @ts-expect-error added by automation
  public RequirementId: number;
  @observable
  // @ts-expect-error added by automation
  public TravellerId: number;
  @observable
  // @ts-expect-error added by automation
  public IsVerified: boolean;
  @observable
  // @ts-expect-error added by automation
  public IsForContact: boolean;
  @observable
  // @ts-expect-error added by automation
  public HasOptions: boolean;
  @observable
  public Options: IObservableArray<ReportingRequirementOptionModel>;
  @computed
  public get FindMaxValue() {
    var maxValue = 0;
    if (this.Options.length === 0) {
      return maxValue + 1;
    }
    this.Options.forEach((option) => {
      if (option.Value > maxValue) {
        maxValue = option.Value;
      }
    });
    return maxValue + 1;
  }
  @computed
  public get SeparateIntoDropDownLists(): { [key: string]: DropdownItem[] } {
    const groupedByNumber = this.Options.slice()
      .sort((a, b) => a.Order - b.Order)
      .reduce((result, obj) => {
        const number = obj.Value;
        // @ts-expect-error added by automation
        if (!result[number]) {
          // @ts-expect-error added by automation
          result[number] = new Array<DropdownItem>();
        }
        var drop = new DropdownItem(obj.Name, number, { Id: obj.Id, Description: obj.Description, Position: number });
        // @ts-expect-error added by automation
        result[number].push(drop);
        return result;
      }, {});

    // Returning an object where each property is a unique number
    return groupedByNumber;
  }
}

export default HotelBookingReportingRequirementModel;
