import dayjs from 'dayjs';
import { computed, makeObservable, observable } from 'mobx';
import { getFormattedServerDate } from '../../utils/helpers/dateHelpers';
import EntityFormModel from '../forms/EntityFormModel';
import ClientEmail from './ClientEmail';
import { IFileModel } from './IFileModelInterface';
import { stringIsNullOrEmpty } from 'src/utils/helpers/stringHelpers';

class ActivityNoteModel extends EntityFormModel implements IFileModel {
  public constructor() {
    super();
    makeObservable(this);
    this.ClientEmail = new ClientEmail();
  }
  @observable
  // @ts-expect-error added by automation
  public Note: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedBy: string;
  @observable
  // @ts-expect-error added by automation
  public AttachedFile: File | null;
  @observable
  // @ts-expect-error added by automation
  public FileName: string;
  @observable
  // @ts-expect-error added by automation
  public FileHandle: string;
  @observable
  // @ts-expect-error added by automation
  public IsFolio: boolean;
  @observable
  public ClientEmail: ClientEmail;
  @observable
  // @ts-expect-error added by automation
  public ClientEmailId: number;
  @observable
  // @ts-expect-error added by automation
  public IsFollowUp: boolean;

  @computed
  public get CreatedOnMoment() {
    return this.CreatedOn ? dayjs.utc(this.CreatedOn).local() : null;
  }

  @computed
  public get CreatedInformation(): string {
    var createdBy = !stringIsNullOrEmpty(this.CreatedBy) ? this.CreatedBy + ' - ' : '';
    return `${createdBy}${this.CreatedOnFormatted}`;
  }

  @computed
  public get CreatedOnFormatted(): string {
    return getFormattedServerDate(this.CreatedOn);
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, ['ClientEmail']);
    this.ClientEmail.SetData(dbData.ClientEmail);
  }
}

export default ActivityNoteModel;
