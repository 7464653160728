import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

export enum SignatureType {
  None = 0,
  Email = 1,
  SMS = 2
}

export class EmailSignatureModel extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public Title: string;

  @observable
  // @ts-expect-error added by automation
  public Signature: string;

  @observable
  // @ts-expect-error added by automation
  public SignatureType: SignatureType;
  @observable
  // @ts-expect-error added by automation
  public IsDefault: boolean;

  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
