import { numberInputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  numberInputAnatomy.keys
);

const frontierInput = definePartsStyle({
  root: {
    borderRadius: 0,
  },
  stepperGroup: {
    borderRadius: 0,
  },
  stepper: {
    borderRadius: 0,
  },
  field: {
    background: 'white.100',
    borderRadius: '2px',
    border: '1px solid #707070',
  },
});

const searchForm = definePartsStyle({
  root: {
    borderRadius: 0,
  },
  stepperGroup: {
    borderRadius: 0,
  },
  stepper: {
    borderRadius: 0,
  },
  field: {
    background: 'white.100',
    borderRadius: '2px',
    boxShadow: 'inset 2px 2px 1px #00000029',
  },
});

export const numberInputTheme = defineMultiStyleConfig({
  variants: {
    frontierInput,
    searchForm,
  },
  defaultProps: {
    variant: 'frontierInput',
  },
});
