import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

class ReportingRequirementOptionModel extends EntityFormModel {
  constructor(model?: ReportingRequirementOptionModel) {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();
    makeObservable(this);
    this.Value = 1;
    if (!!model) {
      this.Name = model.Name;
      this.Value = model.Value;
      this.Title = model.Title;
      this.Description = model.Description;
      this.Order = model.Order;
    }
  }
  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  public Value: number;
  @observable
  // @ts-expect-error added by automation
  public Title: string;
  @observable
  // @ts-expect-error added by automation
  public Description: string;
  @observable
  // @ts-expect-error added by automation
  public Order: number;
}

export default ReportingRequirementOptionModel;
