import { IObservableArray, makeObservable, observable } from 'mobx';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import { ISubjectModel } from '../common/Interfaces/ISubjectModel';
import EntityFormModel from '../forms/EntityFormModel';
import ClientEmailAttachmentModel from './ClientEmailAttachmentModel';

class ClientEmail extends EntityFormModel implements ISubjectModel {
  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
    this.Attachments = new Array<ClientEmailAttachmentModel>() as IObservableArray;
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, ['Attachments']);
    arraySetData(this.Attachments, ClientEmailAttachmentModel, dbData.Attachments);
  }
  @observable
  // @ts-expect-error added by automation
  public IsFolio: boolean;
  @observable
  // @ts-expect-error added by automation
  public IsHotelEmail: boolean;
  @observable
  // @ts-expect-error added by automation
  public Body: string;
  @observable
  // @ts-expect-error added by automation
  public Date: string;
  @observable
  // @ts-expect-error added by automation
  public Cc: string;
  @observable
  // @ts-expect-error added by automation
  public From: string;
  @observable
  // @ts-expect-error added by automation
  public Subject: string;
  @observable
  // @ts-expect-error added by automation
  public To: string;
  @observable
  // @ts-expect-error added by automation
  public UniqueMessageId: number;
  @observable
  public Attachments: IObservableArray<ClientEmailAttachmentModel>;
  @observable
  // @ts-expect-error added by automation
  public SentAttachments: string;
}

export default ClientEmail;
