import { UpDownIcon } from '@chakra-ui/icons';
import { Box, HStack, Menu, MenuButton, MenuItem, MenuList, UseDisclosureReturn } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import { getAxios } from 'src/lib/axios';
import endpoints from 'src/services/Common/endpoints';
import { JoinWorkflowModal as JoinWorkflowButton } from './JoinWorkflowModal';
import { WorkflowInitialJoinModel, WorkflowJoinStatus } from './Types';
import WorkflowList, { WorkListMode } from './WorkflowList';

type Props = {
  disclosure: UseDisclosureReturn;
  setIsInWorkflow: (val: boolean) => void;
};
export default function WorkflowTool(props: Props) {
  const queryJoinStatus = useQuery<AxiosResponse<WorkflowJoinStatus, any>>({
    queryKey: ['WorkflowActivity', 'WorkingHours'],
    queryFn: () => {
      return getAxios().get<WorkflowJoinStatus>(endpoints.workingHours.endpointUrl, {
        params: { userTime: dayjs().utc() }
      });
    }
  });
  const mutateJoinStatus = useMutation({
    mutationFn: (data: WorkflowInitialJoinModel) => {
      return getAxios().post<void>(endpoints.workingHours.endpointUrl, data);
    },
    onSuccess: () => {
      queryJoinStatus.refetch();
    }
  });

  const local = useLocation();
  const [listMode, setListMode] = useState<WorkListMode>(WorkListMode.Min);
  const [isSpectating, setIsSpectating] = useState(false);
  const auth = useAuth();
  //think of this state as sticky, if there shift ends and a query is changed we
  //dont want them to be booted out of there shift
  const [inWorkflow, setInWorkflow] = useState(false);

  useEffect(() => {
    if (queryJoinStatus.data?.data == WorkflowJoinStatus.Join) {
      setInWorkflow(true);
      props.setIsInWorkflow(true);
    } else if (
      queryJoinStatus.data?.data == WorkflowJoinStatus.Prompt &&
      auth.isInRole([ApplicationRoles.alwaysJoinWorkflow], false)
    ) {
      props.disclosure.onOpen();
    }
  }, [queryJoinStatus.data?.data]);

  if (queryJoinStatus.isPending || !queryJoinStatus.data?.data) {
    return <></>;
  }
  if (local.pathname.toLowerCase().includes('login')) {
    return <></>;
  }

  return (
    <Box>
      {queryJoinStatus.data.data == WorkflowJoinStatus.Join || isSpectating || inWorkflow ? (
        <HStack p={1} pl={2}>
          <WorkflowList mode={listMode} />
          <Menu>
            <MenuButton alignSelf={'flex-end'} ml={'auto'}>
              <UpDownIcon />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setListMode(WorkListMode.Min);
                }}>
                Min
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setListMode(WorkListMode.Max);
                }}>
                Max
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setListMode(WorkListMode.Hide);
                }}>
                Hide
              </MenuItem>
              {isSpectating && (
                <MenuItem
                  color={'red'}
                  onClick={() => {
                    setInWorkflow(false);
                    props.setIsInWorkflow(false);
                    setIsSpectating(false);
                  }}>
                  Leave
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </HStack>
      ) : (
        <JoinWorkflowButton
          disclosure={props.disclosure}
          doNotPrompt={inWorkflow}
          joinStatus={queryJoinStatus.data.data}
          onJoin={(modal, spectating) => {
            if (!spectating) {
              mutateJoinStatus.mutate(modal);
            } else {
              setIsSpectating(true);
              setInWorkflow(true);
              props.setIsInWorkflow(true);
            }
          }}
        />
      )}
    </Box>
  );
}
