import dayjs from 'dayjs';
import formatters from '../../common/formatters';
import { IEndDate } from '../../models/common/Interfaces/IEndDate';
import { IStartDate } from '../../models/common/Interfaces/IStartDate';
import { stringIsNullOrEmpty } from './stringHelpers';
import { isNullOrUndefined } from './typeGuards';

export const SetStartDate = (dbData: any, model: IStartDate) => {
  if (!stringIsNullOrEmpty(dbData.StartDate)) {
    let dbStartDate = dbData.StartDate as string;
    if (dbStartDate.endsWith('Z')) {
      //remove timezone information from start/end dates as they do not have times at all. only dates
      dbStartDate = dbStartDate.substring(0, dbStartDate.length - 1);
    }
    model.StartDate = dayjs(dbStartDate, formatters.momentDate).format(formatters.momentDate);
  }
};

export const SetEndDate = (dbData: any, model: IEndDate) => {
  if (!stringIsNullOrEmpty(dbData.EndDate)) {
    let dnEndDate = dbData.EndDate as string;
    if (dnEndDate.endsWith('Z')) {
      //remove timezone information from start/end dates as they do not have times at all. only dates
      dnEndDate = dnEndDate.substring(0, dnEndDate.length - 1);
    }
    model.EndDate = dayjs(dnEndDate, formatters.momentDate).format(formatters.momentDate);
  }
};

export const SetDate = (date: string) => {
  let dnDate = date;
  if (!stringIsNullOrEmpty(date)) {
    if (dnDate.endsWith('Z')) {
      //remove timezone information from start/end dates as they do not have times at all. only dates
      dnDate = dnDate.substring(0, dnDate.length - 1);
    }
    dnDate = dayjs(dnDate, formatters.momentDate).format(formatters.momentDate);
  }
  return dnDate;
};

export const SetDatePropertyName = (dbData: any, model: any, propertyName: string) => {
  if (!stringIsNullOrEmpty(dbData[propertyName])) {
    let dbStartDate = dbData[propertyName] as string;
    if (dbStartDate.endsWith('Z')) {
      //remove timezone information from start/end dates as they do not have times at all. only dates
      dbStartDate = dbStartDate.substring(0, dbStartDate.length - 1);
    }
    model[propertyName] = dayjs(dbStartDate, formatters.momentDate).format(formatters.momentDate);
  }
};

export const DateSortDescFunction = (a: string, b: string): number => {
  if (isNullOrUndefined(a)) {
    return 1;
  }
  if (isNullOrUndefined(b)) {
    return -1;
  }
  var dateA = dayjs(a);
  var dateB = dayjs(b);
  return Number(dateA.isSameOrBefore(dateB));
};

export const sortModelsByDate = <T, TP extends keyof T>(
  a: T,
  b: T,
  propertyName: TP,
  sortDescending: boolean = false
): number => {
  if (a[propertyName] == null && b[propertyName] == null) {
    return 0;
  }
  if (a[propertyName] == null) {
    return sortDescending ? -1 : 1;
  }
  if (b[propertyName] == null) {
    return sortDescending ? 1 : -1;
  }
  const aDate = getDateObjectByName(a, propertyName);
  const bDate = getDateObjectByName(b, propertyName);
  if (sortDescending) {
    // @ts-expect-error added by automation
    return bDate.diff(aDate);
  }
  // @ts-expect-error added by automation
  return aDate.diff(bDate);
};

export const getFormattedServerDate = (date: string) => {
  return `${dayjs.utc(date).local().format(formatters.momentDateTimeUSA)}`;
};

export const getTodayDateAsString = (): string => {
  return dayjs.utc(Date.now()).local().format(formatters.momentDateTimeUSA);
};

export const getDateObjectByName = <T, TP extends keyof T>(model: T, propertyName: TP) => {
  return model[propertyName] ? dayjs.utc(model[propertyName] as unknown as string).local() : null;
};
