import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const variants = {
  blueCard: definePartsStyle({
    container: {
      backgroundColor: 'blue.100',
      borderRadius: '0',
      padding: 0,
      margin: 0
    }
  }),
  roomType: definePartsStyle({
    container: {
      border: 'none',
      borderRadius: '0',
      boxShadow: 'none',
      padding: '4px'
    },
    body: {
      background: '#F6F6F6',
      padding: '2px'
    }
  }),
  pageCard: definePartsStyle({
    container: {
      background: 'white',
      border: '1px solid #ebebeb',
      borderRadius: '8px',
      boxShadow: 'md',
      padding: '8px'
    },
    header: {
      padding: '6px 6px 0px 6px'
    },
    body: {
      padding: '6px'
    }
  })
};

export const cardTheme = defineMultiStyleConfig({
  variants
});
