import { observable, makeObservable } from 'mobx';
import DailyHotelRateType from '../../enums/finance/DailyHotelRateType';
import EntityFormModel from '../forms/EntityFormModel';

class HotelDailyHotelRateModel extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public Rate: number;
  @observable
  // @ts-expect-error added by automation
  public IncludeGstHst: boolean;
  @observable
  // @ts-expect-error added by automation
  public IncludePst: boolean;
  @observable
  // @ts-expect-error added by automation
  public IncludeOtherTaxAmount: boolean;
  @observable
  // @ts-expect-error added by automation
  public OtherTaxRate: number;
  @observable
  // @ts-expect-error added by automation
  public RateType: DailyHotelRateType;

  //This is a UI property only for managing items in the list. Not stored on back end
  @observable
  // @ts-expect-error added by automation
  public Selected: boolean;

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}

export default HotelDailyHotelRateModel;
