import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const frontierInput = definePartsStyle({
  field: {
    background: 'white',
    borderRadius: '2px',
    border: '1px solid #707070',
    marginBottom: '10px',
    _readOnly: {
      background: 'none rgba(188, 188, 188, 0.14)',
      borderColor: 'rgba(130, 130, 130, 0.14)'
    }
  },
  icon: {
    borderRadius: '0'
  }
});

export const inputTheme = defineMultiStyleConfig({
  variants: {
    frontierInput
  },
  defaultProps: {
    variant: 'frontierInput'
  }
});
