import { extendTheme } from '@chakra-ui/react';
import { ChakraThemeConfig } from '@cksoftware/react-base';
import { buttonTheme } from './components/button';
import { cardTheme } from './components/card';
import { formTheme } from './components/form';
import { headerTheme } from './components/heading';
import { inputTheme } from './components/input';
import { modalTheme } from './components/modal';
import { numberInputTheme } from './components/numberInput';
import { selectTheme } from './components/select';
import { switchTheme } from './components/switch';
import { tabsTheme } from './components/tab';
import { tableTheme } from './components/table';
import { textTheme } from './components/text';

const themeConfig: ChakraThemeConfig = {
  paddings: {
    tableHeightBottomPadding: 200
  }
};

export const chakraTheme = extendTheme({
  ...themeConfig,
  config: {
    cssVarPrefix: 'chak'
  },
  components: {
    Card: cardTheme,
    Input: inputTheme,
    Button: buttonTheme,
    Select: selectTheme,
    NumberInput: numberInputTheme,
    Form: formTheme,
    Text: textTheme,
    Modal: modalTheme,
    Heading: headerTheme,
    Tabs: tabsTheme,
    Table: tableTheme,
    Switch: switchTheme
  },
  styles: {
    global: {
      body: {
        background: '#faf9f67f'
      },
      html: {
        overflowY: 'scroll'
      }
    }
  },
  fonts: {
    heading: `'Montserrat Variable', 'sans-serif'`,
    body: `'Montserrat Variable', 'sans-serif'`
  },
  colors: {
    WorkflowGroupActionType: {
      None: '#f8d8d8',
      Status: '#f8d8d8',
      Ready: '#d8f8db',
      Assigned: '#d8eaf8'
    },
    radioButton: {
      selected: '#f05a29',
      unselected: 'white',
      border: '#f05a29'
    },
    icons: {
      selectedBackground: 'white',
      unselectedBackground: 'white',
      selected: '#f05a29',
      unselected: '#255993'
    },
    // orange: {
    //   100: '#f05a29'
    // },
    blue: {
      100: '#255993'
    },
    primary: {
      100: '#f05a29',
      200: '#F37F59'
    },
    secondary: {
      100: '#255993'
    }
  }
});
