import { observable, makeObservable } from 'mobx';

import FormModel from './FormModel';

abstract class EntityFormModel extends FormModel {
  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();
    makeObservable(this);
  }
  @observable
  // @ts-expect-error added by automation
  public Id: number;
}

export default EntityFormModel;
