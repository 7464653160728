import { action, makeObservable } from 'mobx';
import { objectMapper } from '../../utils/helpers/objectHelpers';

interface IFormModel {
  OnModelChange: <T extends keyof FormModel>(key: T, value: any) => void;
  SetData: (dbData: any) => void;
}

abstract class FormModel implements IFormModel {
  constructor() {
    makeObservable(this);
  }

  @action
  public OnModelChange = <T extends keyof FormModel>(key: string | T, value: any) => {
    //@ts-ignore
    this[key] = value;
  };

  @action
  public SetData(dbData: any, ignoreProps: Array<string> = []): void {
    objectMapper(dbData, this, ignoreProps);
  }
}

export default FormModel;
