import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

export class ItineraryHeaderModel extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public ItineraryHeader: string;

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
