import { createContext, useState } from 'react';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import useHookHub from 'src-new/util/hubs/useHookHub';

type ActivityOpenNotificationContextType = {
  // hub: HubReturn<ActivityOpenNotificationServerRpc>;
  onOpenActivity: (activityId: number) => void;
  onCloseActivity: (activityId: number) => void;
  openBy: string | undefined;
};

type ActivityOpenNotificationServerRpc = {
  OnCloseActivity: (activityId: number) => void;
  OnOpenActivity: (activityId: number) => string | undefined;
};

// @ts-expect-error added by automation
export const ActivityOpenNotificationContext = createContext<ActivityOpenNotificationContextType>(null);
// @ts-expect-error added by automation
export default function ActivityOpenNotificationProvider({ children }) {
  // @ts-expect-error added by automation
  const [openBy, setOpenBy] = useState<string>(undefined);

  const [invoke] = useHookHub<ActivityOpenNotificationServerRpc>(ApiEndpoints.hubs.activityNotificationHub, {});
  const onOpenActivity = (activityId: number) => {
    invoke('OnOpenActivity', activityId).then((val) => {
      setOpenBy(val);
    });
  };

  const onCloseActivity = (activityId: number) => {
    invoke('OnCloseActivity', activityId);
  };
  return (
    <ActivityOpenNotificationContext.Provider value={{ onOpenActivity, onCloseActivity, openBy }}>
      {children}
    </ActivityOpenNotificationContext.Provider>
  );
}
