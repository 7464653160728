import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const striped = definePartsStyle(() => {
  return {
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          td: {
            background: '#5c91cc26'
          }
        },
        '&:nth-of-type(even)': {
          td: {
            background: 'white'
          }
        }
      }
    }
  };
});

export const tableTheme = defineMultiStyleConfig({
  variants: { striped },
  defaultProps: { size: 'sm', variant: 'striped' }
});
