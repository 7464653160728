import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const solid = defineStyle({
  backgroundColor: 'primary.100',
  color: 'white',
  borderRadius: '2px',
  minWidth: '100px',
  _hover: {
    backgroundColor: '#f69778'
  },
  _active: {
    backgroundColor: 'grey'
  }
});

const secondary = defineStyle({
  backgroundColor: 'blue.100',
  color: 'white',
  borderRadius: '2px',
  minWidth: '100px',
  _hover: {
    backgroundColor: '#5c91cc'
  },
  _active: {
    backgroundColor: 'grey'
  }
});

const grey = defineStyle({
  backgroundColor: '#E1E1E1',
  _hover: {
    backgroundColor: '#B6B6B6'
  },
  border: '1px solid ##E1E1E1',
  textAlign: 'center',
  borderRadius: '2px',
  color: 'black',
  fontWeight: 'normal',
  width: '100px'
});

const toolbar = defineStyle({
  backgroundColor: '#ffffff',
  _hover: {
    backgroundColor: '#efefef'
  },
  border: '1px solid #dddbdb',
  textAlign: 'center',
  padding: '5px',
  borderRadius: '5px',
  color: 'rgb(50, 49, 48)',
  fontWeight: 'normal',
  fontSize: '14px',
  minWidth: '75px',
  height: '40px'
});

const filterFormButton = defineStyle({
  backgroundColor: 'primary.100',
  color: 'white',
  borderRadius: '8px',
  marginBottom: '10px',

  _hover: {
    backgroundColor: '#f69778'
  },
  _active: {
    backgroundColor: 'grey'
  }
});

const tablePagerActive = defineStyle({
  backgroundColor: 'primary.100',
  color: 'white'
});

export const buttonTheme = defineStyleConfig({
  variants: { solid, grey, secondary, toolbar, tablePagerActive, filterFormButton },
  defaultProps: {
    variant: 'solid'
  }
});
