import { Box, useDisclosure } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import WorkflowTool from 'src-new/features/WorkflowTool/WorkflowTool';
import { NavBar } from 'src-new/features/header/NavBar';
import Callout from 'src/components/callout/Callout';
import Modal from 'src/components/dialog/Modal';
import Toast from 'src/components/toast/Toast';
import OverlayLoadingSpinner from '../loadingSpinner/OverlayLoadingSpinner';

const Layout = () => {
  const auth = useAuth();
  const joinWorkflowDiscolure = useDisclosure();
  const [isInWorkflow, setIsInWorkflow] = useState(false);

  return (
    <div>
      <NavBar joinWorkflowDisclosure={joinWorkflowDiscolure} isInWorkflow={isInWorkflow} />
      {auth.isAuthenticated && <WorkflowTool disclosure={joinWorkflowDiscolure} setIsInWorkflow={setIsInWorkflow} />}
      <Box
        w={['100%', '100%', '80%']}
        maxW={'1800px'}
        margin={'auto'}
        paddingLeft={'5px'}
        paddingRight={'5px'}
        paddingTop={'10px'}>
        <Outlet />
      </Box>
      <Callout />
      <Modal />
      <Toast />
      <OverlayLoadingSpinner />
    </div>
  );
};
export default Layout;
