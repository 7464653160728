import { action, observable, makeObservable } from 'mobx';
class ToastState {
  public constructor() {
    makeObservable(this);
    this.IsOpen = false;
  }

  @observable
  public IsOpen: boolean;

  @observable
  // @ts-expect-error added by automation
  public Title: string;

  @observable
  // @ts-expect-error added by automation
  public Body: string;

  @action
  public ShowToast = (title: string, body: string) => {
    this.IsOpen = true;
    this.Title = title;
    this.Body = body;

    setTimeout(this.HideToast, 3500);
  };

  @action
  public HideToast = () => {
    this.IsOpen = false;
  };
}

export default ToastState;
