import { Box, Popover, PopoverContent, PopoverTrigger, Portal, Spinner } from '@chakra-ui/react';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import ToolTipIconButton from 'src-new/components/Common/ToolTipIconButton';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import { cleanLinks } from 'src-new/features/activityNotes/helpers';
import { getAxios } from 'src/lib/axios';
import { IEmailActivityOverviewModel } from '../../../../models/activities/IEmailActivityOverviewModel';
import styled from '../../../../utils/styled/styled';
import { EmailIframe } from '../../styledComponents';

interface IOverviewTablePreviewEmail {
  model: IEmailActivityOverviewModel;
  isDisabled: boolean;
}
export const PreWrapTextDiv = styled.div`
  white-space: pre-wrap;
`;

const OverviewTablePreviewEmail: React.FC<IOverviewTablePreviewEmail> = (props: IOverviewTablePreviewEmail) => {
  const [doFetch, setDoFetch] = useState(false);
  const emailBodyQuery = useQuery({
    queryKey: ['emailBody', props.model.ClientEmailId],
    queryFn: () => {
      return getAxios().get<string>(
        ApiEndpoints.activities.getActivityInfoEmailBody + props.model.ClientEmailId?.toString()
      );
    },
    enabled: doFetch && props.model.ClientEmailId != null
  });

  return (
    <Popover
      trigger='hover'
      placement='auto'
      onOpen={() => {
        if (!props.isDisabled) {
          setDoFetch(true);
        }
      }}>
      <PopoverTrigger>
        <ToolTipIconButton isDisabled={props.isDisabled} aria-label='' toolTip='' icon={faEye} />
      </PopoverTrigger>
      <Portal>
        <PopoverContent width={'fit-content'} minW={'30vw'} maxW={1000}>
          <Box p={0}>
            {props.model.ClientEmailId?.toString() && emailBodyQuery.data?.data ? (
              <EmailIframe
                style={{ border: '1px solid black', padding: '8px', backgroundColor: 'white', marginBottom: '12px' }}
                maxHeight={'50vh'}
                srcDoc={cleanLinks(emailBodyQuery.data.data)}
              />
            ) : (
              <Spinner ml={'auto'} mr={'auto'} p={4} />
            )}
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default observer(OverviewTablePreviewEmail);
