import { NavItem } from 'src-new/components/MenuNav/types/NavItem';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import Layout from 'src/containers/layout/Layout';

export const AgentEmailNavItems: NavItem = {
  text: 'Agent Emails',
  layout: <Layout />,
  allowedRoles: [ApplicationRoles.reservations],
  subItems: [
    {
      text: 'Inbox',
      path: applicationRoutes.agent.inbox,
      additionalPaths: [`${applicationRoutes.agent.inbox}/:id`],
      allowedRoles: [ApplicationRoles.reservations],
      async lazy() {
        let component = await import('src-new/features/inbox/inbox');
        return { Component: component.default };
      }
    },
    {
      text: 'Inbox Coordinator',
      allowedRoles: [ApplicationRoles.inboxCoordinator],
      path: applicationRoutes.agent.inboxCoordinator,
      additionalPaths: [`${applicationRoutes.agent.inboxCoordinator}/`],
      async lazy() {
        let component = await import('src-new/features/inbox/inboxCoordinator');
        return { Component: component.default };
      }
    },
    {
      text: 'Frontier Quick Docs',
      path: applicationRoutes.admin.quickDocuments,
      async lazy() {
        let component = await import(
          'src/containers/admin/applicationManagement/documentManagement/FrontierDocumentManagement'
        );
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.agent.activityInquiry + '/:id',
      async lazy() {
        let component = await import('src/containers/agent/ActivityInquiry/ActivityInquiryAgentQueryContainer');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.agent.emailEdit,
      async lazy() {
        let component = await import('src-new/features/inbox/inbox');
        return { Component: component.default };
      }
    }
  ]
};
