import { observable, makeObservable } from 'mobx';
import { IActiveStateModel } from 'src/models/common/Interfaces/IActiveStateModel';
import EntityFormModel from 'src/models/forms/EntityFormModel';
import CurrencyCode from './CurrencyCode';

export class CreditCardTypeModel extends EntityFormModel implements IActiveStateModel {
  @observable
  // @ts-expect-error added by automation
  public CardType: string;
  @observable
  // @ts-expect-error added by automation
  public Active: boolean;
  @observable
  public CurrencyCode: CurrencyCode;

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();
    makeObservable(this);
    this.CurrencyCode = CurrencyCode.CAD; // default CAD.
  }
}
