import { useUtilityStore } from '../../stores/useMobxStores';
import { Toast as ReactToast, ToastBody, ToastHeader } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import styled from '../../utils/styled/styled';

const Wrapper = styled.div`
  position: absolute;
  min-height: 200px;
  top: 1em;
  right: 1em;
  z-index: 1000001;
`;

const Toast = () => {
  const store = useUtilityStore();

  return (
    <Wrapper>
      <ReactToast fade isOpen={store.toastState.IsOpen}>
        <ToastHeader icon='success'>{store.toastState.Title}</ToastHeader>
        <ToastBody>{store.toastState.Body}</ToastBody>
      </ReactToast>
    </Wrapper>
  );
};

export default observer(Toast);
