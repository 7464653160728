import { IObjectWithKey } from '@fluentui/react';
import { computed, IObservableArray, makeObservable, observable } from 'mobx';
import HotelContractType from '../../enums/crm/HotelContractType';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import { stringIsNullOrEmpty } from '../../utils/helpers/stringHelpers';
import { Address } from '../common/Address';
import AuditModel from '../common/AuditModel';
import FormOfPaymentModel from '../common/FormOfPaymentModel';
import { IActiveStateModel } from '../common/Interfaces/IActiveStateModel';
import { IFaxNumberModel } from '../common/Interfaces/IFaxNumberModel';
import { INameModel } from '../common/Interfaces/INameModel';
import { INotesModel } from '../common/Interfaces/INotesModel';
import { IPhoneNumberModel } from '../common/Interfaces/IPhoneNumberModel';
import { PreferredHotelModel } from '../companies/PreferredHotelModel';
import { UnPreferredHotelModel } from '../companies/UnPreferredHotelModel';
import EntityFormModel from '../forms/EntityFormModel';
import { Amenity } from './Amenity';
import HotelBookingTypeModel from './HotelBookingTypeModel';
import { HotelContract } from './HotelContract';
import { HotelCustomPaymentModel } from './HotelCustomPaymentModel';
import HotelDailyHotelRateModel from './HotelDailyHotelRateModel';
import { HotelDefaultSeason } from './HotelDefaultSeason';
import { HotelRoomType } from './HotelRoomType';
import NearbyCityModel from './NearbyCityModel';

export class Hotel
  extends EntityFormModel
  implements INameModel, IPhoneNumberModel, IFaxNumberModel, INotesModel, IActiveStateModel, IObjectWithKey
{
  public constructor() {
    super();
    makeObservable(this);
    this.Active = true;
    this.IsCamp = false;
    this.IsLongTermStay = false;
    this.HotelAddress = new Address();
    this.HotelContracts = observable.array(new Array<HotelContract>());
    this.Amenities = observable.array(new Array<Amenity>());
    this.PreferredPaymentMethods = observable.array(new Array<FormOfPaymentModel>());
    this.NearbyCities = observable.array(new Array<NearbyCityModel>());
    this.BookingTypes = observable.array(new Array<HotelBookingTypeModel>());
    this.DailyHotelRates = observable.array(new Array<HotelDailyHotelRateModel>());
    this.RoomTypes = observable.array(new Array<HotelRoomType>());
    this.DefaultSeasons = observable.array(new Array<HotelDefaultSeason>());
    this.PreferredHotel = new PreferredHotelModel();
    this.UnPreferredHotel = new UnPreferredHotelModel();
    this.MissingTravellerRequiredAmenities = observable.array(new Array<Amenity>());
    this.MissingCompanyRequiredAmenities = observable.array(new Array<Amenity>());
    this.CustomPaymentMethods = observable.array(new Array<HotelCustomPaymentModel>());
    this.Audits = observable.array(new Array<AuditModel>());
    this.CodeRedNotes = observable.array(new Array<string>());
    this.AgentNotes = observable.array(new Array<string>());
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, [
      'HotelAddress',
      'HotelContracts',
      'Amenities',
      'MissingCompanyRequiredAmenities',
      'MissingTravellerRequiredAmenities',
      'RoomTypes',
      'PreferredPaymentMethods',
      'BookingTypes',
      'PreferredHotel',
      'UnPreferredHotel',
      'NearbyCities',
      'CustomPaymentMethods',
      'DailyHotelRates',
      'DefaultSeasons',
      'Audits',
      'HotelNotes',
      'AgentNotes'
    ]);
    this.HotelAddress.SetData(dbData.HotelAddress);
    if (dbData.PreferredHotel == null) {
      // @ts-expect-error added by automation
      this.PreferredHotel = null;
    } else {
      if (this.PreferredHotel == null) {
        this.PreferredHotel = new PreferredHotelModel();
      }
      this.PreferredHotel.SetData(dbData.PreferredHotel);
    }
    if (dbData.UnPreferredHotel == null) {
      // @ts-expect-error added by automation
      this.UnPreferredHotel = null;
    } else {
      this.UnPreferredHotel.SetData(dbData.UnPreferredHotel);
    }

    arraySetData(this.Amenities, Amenity, dbData.Amenities);
    arraySetData(this.RoomTypes, HotelRoomType, dbData.RoomTypes);
    arraySetData(this.PreferredPaymentMethods, FormOfPaymentModel, dbData.PreferredPaymentMethods);
    arraySetData(this.BookingTypes, HotelBookingTypeModel, dbData.BookingTypes);
    arraySetData(this.MissingTravellerRequiredAmenities, Amenity, dbData.MissingTravellerRequiredAmenities);
    arraySetData(this.MissingCompanyRequiredAmenities, Amenity, dbData.MissingCompanyRequiredAmenities);
    arraySetData(this.HotelContracts, HotelContract, dbData.HotelContracts);
    arraySetData(this.NearbyCities, NearbyCityModel, dbData.NearbyCities);
    arraySetData(this.CustomPaymentMethods, HotelCustomPaymentModel, dbData.CustomPaymentMethods);
    arraySetData(this.DefaultSeasons, HotelDefaultSeason, dbData.DefaultSeasons);
    arraySetData(this.DailyHotelRates, HotelDailyHotelRateModel, dbData.DailyHotelRates);
    arraySetData(this.Audits, AuditModel, dbData.Audits);
  }
  @observable
  public Active: boolean;
  @observable
  // @ts-expect-error added by automation
  public Preferred: boolean;
  @observable
  // @ts-expect-error added by automation
  public HotelColor: number;
  @observable
  public IsCamp: boolean;
  @observable
  public CodeRedNotes: IObservableArray<string>;
  @observable
  public AgentNotes: IObservableArray<string>;
  @observable
  public IsLongTermStay: boolean;
  @observable
  // @ts-expect-error added by automation
  public CampNotes: string;
  @observable
  // @ts-expect-error added by automation
  public LongTermStayNotes: string;
  @observable
  public PreferredHotel: PreferredHotelModel;
  @observable
  public UnPreferredHotel: UnPreferredHotelModel;
  @observable
  // @ts-expect-error added by automation
  public ParentCompanyId: number;
  @observable
  // @ts-expect-error added by automation
  public ToBeApproved: boolean;
  @observable
  public HotelAddress: Address;
  @observable
  public HotelContracts: IObservableArray<HotelContract>;
  @observable
  // @ts-expect-error added by automation
  public HotelAddressId: number;
  @observable
  // @ts-expect-error added by automation
  public PhoneNumber: string;
  @observable
  // @ts-expect-error added by automation
  public FaxNumber: string;
  @observable
  // @ts-expect-error added by automation
  public Email: string;
  @observable
  // @ts-expect-error added by automation
  public Agent: string;
  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public Notes: string;
  @observable
  // @ts-expect-error added by automation
  public GeneralManager: string;
  @observable
  // @ts-expect-error added by automation
  public SalesManager: string;
  @observable
  public Amenities: IObservableArray<Amenity>;
  @observable
  public MissingCompanyRequiredAmenities: IObservableArray<Amenity>;
  @observable
  public MissingTravellerRequiredAmenities: IObservableArray<Amenity>;
  @observable
  // @ts-expect-error added by automation
  public ApolloId: string;
  @observable
  // @ts-expect-error added by automation
  public ChainCode: string;
  @observable
  public PreferredPaymentMethods: IObservableArray<FormOfPaymentModel>;
  @observable
  public NearbyCities: IObservableArray<NearbyCityModel>;
  @observable
  public BookingTypes: IObservableArray<HotelBookingTypeModel>;
  @observable
  public DailyHotelRates: IObservableArray<HotelDailyHotelRateModel>;
  @observable
  public RoomTypes: IObservableArray<HotelRoomType>;
  @observable
  // @ts-expect-error added by automation
  public AirportCodes: string;
  @observable
  // @ts-expect-error added by automation
  public Brand: string;
  @observable
  // @ts-expect-error added by automation
  public BrandContact: string;
  @observable
  // @ts-expect-error added by automation
  public BrandNotes: string;
  @observable
  // @ts-expect-error added by automation
  public CancellationPolicy: string;
  @observable
  // @ts-expect-error added by automation
  public ManagementCompany: string;
  @observable
  // @ts-expect-error added by automation
  public OwnershipGroup: string;
  @observable
  // @ts-expect-error added by automation
  public CommissionCollectionMethod: string;
  @observable
  // @ts-expect-error added by automation
  public CommissionCollectionContact: string;
  @observable
  // @ts-expect-error added by automation
  public CommissionCollectionNote: string;
  @observable
  // @ts-expect-error added by automation
  public PaymentEmail: string;
  @observable
  // @ts-expect-error added by automation
  public PaymentNotes: string;
  @observable
  // @ts-expect-error added by automation
  public NonGdsHotelId: string;
  @observable
  // @ts-expect-error added by automation
  public BookingMethod: string;
  @observable
  // @ts-expect-error added by automation
  public BookingMethodNotes: string;
  @observable
  // @ts-expect-error added by automation
  public Website: string;
  @observable
  // @ts-expect-error added by automation
  public WebsiteNotes: string;
  @observable
  // @ts-expect-error added by automation
  public CrossesIntoBlackoutDate: boolean;
  @observable
  // @ts-expect-error added by automation
  public CrossBlackoutReason: string;
  @observable
  // @ts-expect-error added by automation
  public CrossesIntoSeason: boolean;
  @observable
  public DefaultSeasons: Array<HotelDefaultSeason>;
  @observable
  // @ts-expect-error added by automation
  public IsPreferredHotel: boolean;
  @observable
  public CustomPaymentMethods: IObservableArray<HotelCustomPaymentModel>;
  @observable
  public Audits: IObservableArray<AuditModel>;
  @observable
  // @ts-expect-error added by automation
  public ProjectCommision: string;
  @observable
  // @ts-expect-error added by automation
  public ProjectRate: string;
  @observable
  // @ts-expect-error added by automation
  public ExcludeFromObt: boolean;

  @observable
  public UsesComDataCard: boolean = false;

  @observable
  // @ts-expect-error added by automation
  public OBTTermsAndConditions: string;

  @computed
  public get SortedRoomRates() {
    return this.RoomTypes.filter((x) => x.Active)
      .slice()
      .sort((a, b) => a.ActiveRate?.SingleRate - b.ActiveRate?.SingleRate);
  }

  @computed
  private get currentContracts() {
    return this.HotelContracts.filter((x) => x.IsCurrent);
  }

  public CurrentContractsAsOfDate = (date: string) => {
    if (stringIsNullOrEmpty(date)) {
      return this.currentContracts;
    }
    return this.HotelContracts.filter((x) => x.IsCurrentDateRange(date));
  };

  public DiscountCommissionPercentage = (type: HotelContractType, date?: string): number => {
    return this.FirstCurrentContractOfType(type, date)?.DiscountCommissionPercentage;
  };

  public CommissionPercentage = (type: HotelContractType, date?: string): number => {
    return this.FirstCurrentContractOfType(type, date)?.CommissionPercentage;
  };

  public DiscountPercentage = (type: HotelContractType, date?: string): number => {
    return this.FirstCurrentContractOfType(type, date)?.DiscountPercentage;
  };

  public CommissionPercentageDescription = (type: HotelContractType, date?: string): string => {
    return this.FirstCurrentContractOfType(type, date)?.CommissionPercentageDescription;
  };

  @computed
  public get key() {
    return this.Id;
  }

  public FirstCurrentContractOfType = (type: HotelContractType, date?: string): HotelContract => {
    // @ts-expect-error added by automation
    const current = this.CurrentContractsAsOfDate(date).filter((x) => x.HotelContractType === type);
    // @ts-expect-error added by automation
    return current.length > 0 ? current[0] : null;
  };
}
