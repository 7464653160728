import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const frontierHeader = defineStyle({
  fontSize: '24px',
  paddingTop: '5px',
  paddingBottom: '5px'
});

const modalHeader = defineStyle({
  fontSize: '24px',
  paddingTop: '5px',
  paddingBottom: '0px'
});

export const headerTheme = defineStyleConfig({
  variants: {
    frontierHeader,
    modalHeader
  },
  defaultProps: {
    variant: 'frontierHeader'
  }
});
