import { API_URL } from 'src/lib/config';

export const ApiEndpoints = {
  selectLists: {
    usersInRoles: '/api/SelectList/UsersInRoles',
    companies: 'api/SelectList/Companies'
  },
  activities: {
    getActivity: '/api/activity',
    getActivityPage: '/api/activity/page',
    getActivityIdsFromFlsq: '/api/activity/get/flsq',
    getActivityInfoEmailBody: '/api/ActivityInformation/emailBody/'
  },
  announcement: {
    get: '/api/admin/Announcement'
  },
  mirAdmin: {
    mirFileReport: '/api/MirAdmin/MirFileReport',
    mirAgentErrorReport: '/api/MirAgentErrorReport',
    mirActivityReport: '/api/MirAdmin/ActivityMissingMirReport',
    put: '/api/MirAdmin',
    import: '/api/MirAdmin/RunImport'
  },
  hubs: {
    activityNotificationHub: API_URL + '/hubs/ActivityOpenNotificationHub',
    workFlowHub: API_URL + '/hubs/WorkflowHub'
  },
  reporting: {
    agentActivityReport: {
      get: (startDate: string, endDate: string) => `/api/AgentActivityReport?startDate=${startDate}&endDate=${endDate}`,
      send: (startDate: string, endDate: string) =>
        `/api/AgentActivityReport/Send?startDate=${startDate}&endDate=${endDate}`
    }
  }
};
