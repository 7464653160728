import ActivityNoteModel from 'src/models/activities/ActivityNoteModel';
import { ModelType } from './types';

export function cleanLinks(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const links = doc.querySelectorAll('a');

  links.forEach((link) => {
    link.setAttribute('target', '_blank');
    // for security reasons
    link.setAttribute('rel', 'noopener noreferrer');
  });

  const serializer = new XMLSerializer();
  const serializedHtml = serializer.serializeToString(doc);

  return serializedHtml;
}

export type getModelTypeAndNoteDataReturn = { modelType: ModelType; isEmail: boolean; isPdf: boolean };

export function getModelTypeAndNoteData(item: ActivityNoteModel): getModelTypeAndNoteDataReturn {
  let modelType: ModelType;
  let isEmail = false;
  let isPdf = false;

  if (item.IsFollowUp) {
    modelType = ModelType.FollowUp;
  } else if (item.ClientEmail.From) {
    const isInbound = item?.ClientEmail?.To?.toLowerCase().includes('frontier');
    modelType = isInbound ? ModelType.Inbound : ModelType.Outbound;
    isEmail = true;
  } else if (item.FileName?.toLowerCase()?.endsWith('mir')) {
    modelType = ModelType.Mir;
  } else if (item.Note) {
    if (item.FileName && item.FileName.toLowerCase().endsWith('pdf')) {
      isPdf = true;
    }
    if (item.FileName && item.FileName.toLowerCase().endsWith('eml')) {
      isEmail = true;
    }
    modelType = ModelType.Note;
  } else {
    modelType = ModelType.File;
    isPdf = item.FileName ? item.FileName.toLowerCase()?.endsWith('pdf') : false;
  }
  return { modelType, isPdf, isEmail };
}
