import { Box, Button, HStack, Icon } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren } from 'react';

type ToolbarButtonProps = PropsWithChildren & {
  icon: IconDefinition;
  onClick: () => void;
};

export const ToolbarButton = (props: ToolbarButtonProps) => {
  return (
    <Button variant={'toolbar'} onClick={props.onClick}>
      <HStack>
        <Icon color={'blue.100'} as={FontAwesomeIcon} icon={props.icon} aria-label=''></Icon>
        <Box>{props.children}</Box>
      </HStack>
    </Button>
  );
};
