import ActivityAlertLevel from '../enums/activities/ActivityAlertLevel';
import ActivityStatus from '../enums/activities/ActivityStatus';
import theme from '../theme';

interface Color {
  backgroundColor: string;
  color: string;
  borderBottom?: string;
}

export function ActivityAlertColor(level: ActivityAlertLevel): Color {
  // @ts-expect-error added by automation
  const backgroundColor = theme.EmailBackgroundColours[ActivityAlertLevel[level]];
  // @ts-expect-error added by automation
  const color = theme.EmailColours[ActivityAlertLevel[level]];
  return { backgroundColor: backgroundColor, color: color };
}

export function ActivityStatusColor(status: ActivityStatus, index?: number): Color {
  // @ts-expect-error added by automation
  var backgroundColor = theme.EmailBackgroundColours[ActivityStatus[status]];
  if (index && status == 0) {
    index % 2 !== 0
      ? (backgroundColor = theme.EmailBackgroundColours.Alt)
      : (backgroundColor = theme.EmailBackgroundColours.Basic);
  }
  //const color = theme.EmailColours.Status[ActivityStatus[status]];
  return { backgroundColor: backgroundColor, color: 'black', borderBottom: '1px solid black' };
}
