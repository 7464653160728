import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

export class ActivityDeletedPostModel extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public DeletedReason: string;
  @observable
  public ActivityId: number;

  constructor(activityId: number) {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();
    this.ActivityId = activityId;
    makeObservable(this);
  }
}
