import {
  DirectionalHint,
  IContextualMenuItem,
  IContextualMenuListProps,
  IRenderFunction,
  ISearchBoxStyles,
  SearchBox
} from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useUsersInRolesDropdownQuery } from 'src-new/util/queries/useUsersInRolesQuery';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import { stringIsNullOrEmpty } from 'src/utils/helpers/stringHelpers';
import FaIcon from '../../../../components/common/FaIcon';
import HubContextMenu from '../../../../components/menu/HubContextMenu';
import CalloutWidth from '../../../../enums/common/CalloutWidth';
import HotelBookingQuoteInquiryModel from '../../../../models/QuoteInquiry/HotelBookingQuoteInquiryModel';
import ActivityContextMenuDefinitionModel from '../../../../models/activities/ActivityContextMenuDefinitionModel';
import ActivityManagementService from '../../../../services/Activities/ActivityManagementService';
import { useUserStore, useUtilityStore } from '../../../../stores/useMobxStores';
import styled from '../../../../utils/styled/styled';
import InboxContextMenuCommentsCallout from './InboxContextMenuCommentsCallout';
import { InboxContextMenuPriority } from './InboxContextMenuPriority';
import InboxContextMenuSetFolioCallout from './InboxContextMenuSetFolioCallout';
import { InboxContextMenuStatus } from './InboxContextMenuStatus';
import InboxManagerService from 'src/services/InboxManager/InboxManagerService';
import { useAuth } from '@cksoftware/react-base';

const PaddedDiv = styled.div`
  margin-right: 7px;
`;

const searchBoxStyles: ISearchBoxStyles = {
  root: { margin: '8px' }
};

interface IActivityEmailListContextMenu {
  model: ActivityContextMenuDefinitionModel;
  activityList?: boolean;
  manager?: boolean;
}

const onChangeUser = async (activityId: number, userId: string) => {
  const service = new ActivityManagementService();
  service.updateActivityAssignedUser(activityId, userId);
};

const onAddToManagerList = (activityId: number) => {
  const onAdd = async () => {
    const service = new InboxManagerService();
    await service.addToManagerList(activityId);
  };
  onAdd();
};
const InboxHubContextMenu: React.FC<IActivityEmailListContextMenu> = (props) => {
  const ref = React.useRef<HTMLDivElement>();
  const auth = useAuth();
  const utilStore = useUtilityStore();
  const assignToDropdown = useUsersInRolesDropdownQuery([ApplicationRoles.reservations, ApplicationRoles.folio]);
  const userStore = useUserStore();
  const user = userStore.LazyUserModel.current();

  const onSetPriorityNote = () => {
    const model = new HotelBookingQuoteInquiryModel();
    model.Id = props.model.HotelBookingQuoteInquiryId;
    model.PriorityNote = props.model.PriorityNote;
    utilStore.calloutState.open(
      // @ts-expect-error added by automation
      ref,
      <InboxContextMenuCommentsCallout model={model} activityId={props.model.Id} />,
      CalloutWidth.Mid,
      DirectionalHint.rightCenter,
      () => true
    );
  };

  const priorityArray = InboxContextMenuPriority(props.model, onSetPriorityNote);

  // @ts-expect-error added by automation
  const statusArray = InboxContextMenuStatus(props.model, ref);

  const [assignedUser, setAssignedUser] = React.useState<Array<IContextualMenuItem>>(new Array<IContextualMenuItem>());

  React.useEffect(() => {
    if (assignToDropdown.isLoading) {
      return;
    }
    // @ts-expect-error added by automation
    var userList = assignToDropdown.data.map((user) => {
      return { text: user.Text, key: user.Value == 'Unassign' ? null : user.Value };
    });
    setAssignedUser(userList);
  }, [assignToDropdown.isLoading]);

  const onChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
      // @ts-expect-error added by automation
      const allUsers = assignToDropdown.data.map((user) => {
        return { text: user.Text, key: user.Value == 'Unassign' ? null : user.Value };
      });
      const filteredItems = allUsers.filter(
        (item) => item.text && item.text.toLowerCase().indexOf(newValue.toLowerCase()) !== -1
      );
      if (!filteredItems || !filteredItems.length) {
        filteredItems.push({ text: 'No Results', key: null });
      }
      if (stringIsNullOrEmpty(newValue)) {
        setAssignedUser(allUsers);
      } else {
        setAssignedUser(filteredItems.slice());
      }
    },
    [setAssignedUser]
  );

  const onAbort = React.useCallback(() => {
    setAssignedUser(
      // @ts-expect-error added by automation
      assignToDropdown.data.map((user) => {
        return { text: user.Text, key: user.Value == 'Unassign' ? null : user.Value };
      })
    );
  }, []);

  const renderMenuList = React.useCallback(
    (menuListProps: IContextualMenuListProps, defaultRender: IRenderFunction<IContextualMenuListProps>) => {
      return (
        <div>
          <SearchBox
            ariaLabel='Filter actions by text'
            placeholder='Filter actions'
            onAbort={onAbort}
            // @ts-expect-error added by automation
            onChange={onChange}
            styles={searchBoxStyles}
          />
          {defaultRender(menuListProps)}
        </div>
      );
    },
    [onAbort, onChange]
  );

  const menuItems = React.useMemo(() => {
    const array = new Array<IContextualMenuItem>();

    array.push(
      {
        key: 'AssignedToUserId',
        text: 'Assign to Other',
        iconProps: {
          //Not sure why but you need this here to render the icon....
          iconName: 'Share'
        },
        onRenderIcon: () => {
          return <FaIcon altBaseClassName='far' iconName='fa-user-friends' />;
        },
        calloutProps: {
          calloutMaxHeight: 1000
        },
        subMenuProps: {
          // @ts-expect-error added by automation
          onRenderMenuList: renderMenuList,
          items: assignedUser,
          onItemClick(ev, item) {
            // @ts-expect-error added by automation
            onChangeUser(props.model.Id, item.key);
          }
        }
      },
      {
        key: 'AssignToSelf',
        text: 'Assign to Self',
        iconProps: {
          //Not sure why but you need this here to render the icon....
          iconName: 'Share'
        },
        onRenderIcon: () => {
          return <FaIcon altBaseClassName='far' iconName='fa-user-check' />;
        },
        onClick: () => {
          // @ts-expect-error added by automation
          onChangeUser(props.model.Id, user?.UserId);
        }
      },
      {
        key: 'Status',
        text: 'Set Status',
        iconProps: {
          //Not sure why but you need this here to render the icon....
          iconName: 'Share'
        },
        onRenderIcon: () => {
          return <FaIcon altBaseClassName='far' iconName='fa-question' />;
        },
        subMenuProps: {
          items: statusArray
        }
      },
      {
        key: 'AlertLevel',
        text: 'Set Priority',
        iconProps: {
          //Not sure why but you need this here to render the icon....
          iconName: 'Share'
        },
        onRenderIcon: () => {
          return <FaIcon altBaseClassName='far' iconName='fa-exclamation' />;
        },
        subMenuProps: {
          items: priorityArray
        }
      }
    );
    auth.isInRole([ApplicationRoles.inboxCoordinator]) &&
      array.push({
        key: 'Manager',
        text: 'Add to Priority List',
        iconProps: {
          //Not sure why but you need this here to render the icon....
          iconName: 'Share'
        },
        onRenderIcon: () => {
          return <FaIcon altBaseClassName='far' iconName='fa-exclamation' />;
        },
        onClick: () => onAddToManagerList(props.model.Id)
      });
    props.activityList &&
      array.push(
        {
          key: 'PriorityNote',
          text: 'Add Comments',

          iconProps: {
            //Not sure why but you need this here to render the icon....
            iconName: 'Share'
          },
          onRenderIcon: () => {
            return <FaIcon altBaseClassName='far' iconName='fa-comment-plus' />;
          },
          onClick: () => onSetPriorityNote()
        },
        {
          key: 'FolioList',
          text: 'Add to Folio Email Rules',

          iconProps: {
            //Not sure why but you need this here to render the icon....
            iconName: 'Share'
          },
          onRenderIcon: () => {
            return <FaIcon altBaseClassName='far' iconName='fa-comment-plus' />;
          },
          onClick: () => {
            // @ts-expect-error added by automation
            utilStore.calloutState.open(ref, <InboxContextMenuSetFolioCallout model={props.model} />, CalloutWidth.Mid);
          }
        }
      );

    return array;
  }, [
    renderMenuList,
    assignedUser,
    statusArray,
    priorityArray,
    props.activityList,
    props.model,
    user?.UserId,
    utilStore.calloutState,
    props.model.ItinerarySelectedRateId
  ]);

  return (
    // @ts-expect-error added by automation
    <PaddedDiv ref={ref}>
      <HubContextMenu contextItems={menuItems} />
    </PaddedDiv>
  );
};
export default observer(InboxHubContextMenu);
