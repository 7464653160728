import { Avatar, Button, HStack, Stack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import { useContext, useEffect, useState } from 'react';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import { WorkflowGroupActionType } from './Types';
import { LinkButton } from './Utils';
import { WorkflowActionButton } from './WorkflowActions';
import { WorkflowContext } from './WorkflowProvider';
import { WorkflowUserProps } from './WorkflowUser';
dayjs.extend(durationPlugin);

export function WorkflowCurrentUser({ user, inboxManager, actions }: WorkflowUserProps) {
  const [model, invoke] = useContext(WorkflowContext);
  const [time, setTime] = useState<durationPlugin.Duration>();
  const [retrigger, setRetrigger] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (model.data?.CurrentUser?.WorkingHours) {
      const currentTime = dayjs();
      const endTime = dayjs(model.data?.CurrentUser?.WorkingHours?.EndDate);
      const diff = dayjs.duration(endTime.diff(currentTime, 'date'));
      if (time && time?.asSeconds() <= 0 && diff.asSeconds() > 0) {
        setRetrigger(!retrigger);
      }
      setTime(diff);
    }
  }, [model.data?.CurrentUser.WorkingHours?.EndDate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((previous: any) => {
        let value = previous.subtract(1, 'second');
        if (value.asSeconds() <= 0) {
          // @ts-expect-error added by automation
          invoke?.('EndShift', model.data?.CurrentUser);
          clearInterval(timer);
        }
        return value.seconds() < 0 ? previous : value;
      });
    }, 1000);

    return () => {
      return clearInterval(timer);
    };
  }, [retrigger]);
  return (
    <VStack
      p={1}
      m={0}
      pr={2}
      pb={1}
      bg={`WorkflowGroupActionType.${WorkflowGroupActionType[user.CurrentStatus.AssignedActionType]}`}
      border={'1px'}
      borderColor={'black'}
      height={'100%'}>
      <HStack align={'center'} w={'100%'} alignSelf={'flex-start'} justify={'space-between'}>
        <Tooltip label={user.Name}>
          <Avatar
            size={'xs'}
            textTransform={'none'}
            cursor={'grab'}
            name={user.Name}
            backgroundColor={user.BubbleColor}
            getInitials={(n) =>
              n
                .split(/\s+/)
                .filter((word) => word !== '')
                .map((word, index) => (index === 0 ? word.slice(0, 2) : index === 1 ? word.charAt(0) : ''))
                .join('')
            }></Avatar>
        </Tooltip>

        <Tooltip label={`Time remaining in shift`} isDisabled={!time}>
          <Text as={'b'} fontSize={'xs'} width={'6em'} visibility={'hidden'}>
            {time && time.format('H:mm:ss')}
          </Text>
        </Tooltip>
        <Stack gap={0}>
          <Tooltip label={inboxManager} isDisabled={!inboxManager}>
            <Text fontSize={'2xs'} as={'b'} noOfLines={1}>
              {inboxManager && `Coord: ${inboxManager}`.toUpperCase()}
            </Text>
          </Tooltip>
          {auth.isInRole([ApplicationRoles.inboxCoordinator]) && (
            <LinkButton
              fontSize='0.6em'
              onClick={() => {
                // @ts-expect-error added by automation
                invoke('SetInboxManager', user.UserId, user.Name, model.data.WorkflowGroupId);
              }}>
              Set Coordinator
            </LinkButton>
          )}
        </Stack>
      </HStack>
      <HStack gap={1}>
        {user.ActionTotal.map((total, index) => {
          let action = actions[index];
          return (
            <WorkflowActionButton
              action={action}
              key={`${action.ActionName}-${user.UserId}`}
              userId={user.UserId}
              count={total}
              user={user}
              userStatus={user.CurrentStatus}
            />
          );
        })}
      </HStack>
      <HStack w='100%' justify={'space-between'} pr='1' pl='2'>
        <Tooltip
          label={`${user.CurrentStatus.StatusMessage} ${
            user.CurrentStatus.StatusReturnTime
              ? ` returning at ${dayjs(user.CurrentStatus.StatusReturnTime).format('H:mm a')}`.toUpperCase()
              : ''
          }`}
          hasArrow>
          <Text width={'6em'} fontSize={'xs'} textAlign={'center'} noOfLines={1} maxW={'7em'} mb={0}>
            {user.CurrentStatus.StatusMessage}
          </Text>
        </Tooltip>
        {}
        <LinkButton
          fontSize='0.6em'
          onClick={() => {
            // @ts-expect-error added by automation
            invoke('UndoLast', user, user.UserId, model.data.WorkflowGroupId);
          }}>
          Undo
        </LinkButton>
        {time && time.asMinutes() < 30 && (
          <HStack gap={'0.2em'}>
            {[15, 30, 60].map((v) => (
              <Button
                key={v}
                p='1'
                colorScheme={'blue'}
                minW={'fit-content'}
                size={'2xs'}
                borderRadius={'0'}
                onClick={() => {
                  // @ts-expect-error added by automation
                  invoke('ExtendWorkingHours', model.data?.CurrentUser, v);
                }}
                fontSize={'0.7em'}>
                {'+' + v + 'M'}
              </Button>
            ))}
          </HStack>
        )}
      </HStack>
    </VStack>
  );
}
