import { makeObservable, observable } from 'mobx';
import FormModel from '../forms/FormModel';

class HotelVerificationReturnModel extends FormModel {
  @observable
  public Exists: boolean;

  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public City: string;

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();
    this.Exists = false;
    makeObservable(this);
  }
}

export default HotelVerificationReturnModel;
