import { observer } from 'mobx-react-lite';
import React from 'react';
import { Flex } from '../../../../components/base/flex';
import { IEmailActivityOverviewModel } from '../../../../models/activities/IEmailActivityOverviewModel';
import { getActivityOverviewTitle } from '../../../../utils/activities/activityModelHelpers';
import styled from '../../../../utils/styled/styled';
import OverviewTablePreviewEmail from './OverviewTablePreviewEmail';

interface IActivityEmailDetailsListSubject {
  model: IEmailActivityOverviewModel;
  massMerge?: boolean;
}
export const PreWrapTextDiv = styled.div`
  white-space: pre-wrap;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

let stylesMarginRight = {
  marginRight: '5px',
  marginLeft: '5px'
};

let stylesNoMargin = {
  marginRight: '0px',
  marginLeft: '0px'
};

let stylesMarginLeft = {
  marginRight: '0px',
  marginLeft: '5px'
};

const ActivityEmailDetailsListSubject: React.FC<IActivityEmailDetailsListSubject> = (
  props: IActivityEmailDetailsListSubject
) => {
  return (
    <Flex justifyBetween>
      <FlexContainer>
        {!props.massMerge && <OverviewTablePreviewEmail model={props.model} isDisabled={!props.model.ClientEmailId} />}
        <PreWrapTextDiv>{getActivityOverviewTitle(props.model)}</PreWrapTextDiv>
      </FlexContainer>
      <div>
        {!!props.model.FileName && <i className={'fa fa-envelope-o'} style={stylesNoMargin}></i>}
        {!!props.model.attachmentcount && <i className={'fa fa-paperclip'} style={stylesMarginRight}></i>}
        {props.model.attachmentcount === 0 && <i className={'fa fa-fw'} style={stylesMarginLeft}></i>}
      </div>
    </Flex>
  );
};

export default observer(ActivityEmailDetailsListSubject);
