import { Avatar, Button, Flex, Menu, MenuButton } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { NavMenuItem } from 'src-new/components/MenuNav/NavMenuItem';
import { NavMenuSubItemList } from 'src-new/components/MenuNav/NavMenuSubItemList';
import { NavItem } from 'src-new/components/MenuNav/types/NavItem';
import { useFrontierAuth } from 'src-new/util/auth/useFrontierAuth';
import { AuthenticatedRouteItems } from 'src/app/routes/FrontierRoutes';
import { LoginRouteItem, UserNavItems } from 'src/app/routes/SectionRoutes/AccountRoutes';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import GeneralSearchDialog from 'src/containers/search/GeneralSearchDialog';
import ModalWidth from 'src/enums/common/ModalWidth';
import { useUtilityStore } from 'src/stores/useMobxStores';

export const NavMenus = () => {
  const auth = useFrontierAuth();
  const utilStore = useUtilityStore();

  const [navItems, setNavItems] = useState<NavItem[]>(AuthenticatedRouteItems);

  useEffect(() => {
    if (auth.user == null) {
      setNavItems([LoginRouteItem]);
      return;
    }
  }, [auth.isAuthenticated, auth.user]);

  const renderGeneralSearch = useMemo(() => {
    return auth.isAuthenticated && auth.isInRole([ApplicationRoles.staff]);
  }, [auth.isAuthenticated, auth.user?.Roles]);

  //TODO: fix this general search hack
  return (
    <Flex fontSize={{ base: 'x-small', xl: 'small', '2xl': 'medium' }} gap={{ base: '4px', xl: '8px', '2xl': '20px' }}>
      {renderGeneralSearch && (
        <NavMenuItem
          item={{
            text: 'General Search',
            onClick: () => {
              utilStore.modalState.open(
                <GeneralSearchDialog allowEditUser={auth.isInRole([ApplicationRoles.crm])} />,
                ModalWidth.Fullscreen
              );
            }
          }}
        />
      )}
      {navItems.map((item, index) => {
        // @ts-expect-error added by automation
        item.isActive = window.location.href.indexOf(item.path) > -1;
        // OR is the current url one of its children
        if (item.subItems && item.subItems.length > 0) {
          for (var i = 0; i < item.subItems.length; i++) {
            // @ts-expect-error added by automation
            if (window.location.href.indexOf(item.subItems[i].path) > -1) {
              item.isActive = true;
            }
          }
        }
        return <NavMenuItem key={index} item={item} />;
      })}
      {auth.isAuthenticated && (
        <Menu size={'xs'}>
          <MenuButton as={Button} rounded={'full'} variant={'primary'} cursor={'pointer'} size={'xs'}>
            <Avatar
              borderWidth={'1px'}
              borderStyle={'solid'}
              borderColor={'primary.100'}
              backgroundColor={'white'}
              color={'primary.100'}
              size={'md'}
              name={auth.user?.Name}
            />
          </MenuButton>
          <NavMenuSubItemList subItems={UserNavItems}></NavMenuSubItemList>
        </Menu>
      )}
    </Flex>
  );
};
