import { Selection } from '@fluentui/react';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import CompanyActivitiesHub from '../hubs/Activities/CompanyActivitiesHub';
import HotelActivitiesHub from '../hubs/Activities/HotelActivitiesHub';
import ActivityFormHelperResponseModel from '../models/activities/ActivityFormHelperResponseModel';
import FormOfPaymentModel from '../models/common/FormOfPaymentModel';
import ShowActiveModel from '../models/common/ShowActiveModel';
import { CompanyModel } from '../models/companies/CompanyModel';
import CompanyQueryModel from '../models/companies/CompanyQueryModel';
import ReportingRequirement from '../models/companies/ReportingRequirement';
import { LoyaltyAccount } from '../models/contacts/LoyaltyAccount';
import DailyHotelRateModel from '../models/finance/DailyHotelRateModel';
import DropdownItem from '../models/forms/DropdownItem';
import GroupQueryModel from '../models/groups/GroupQueryModel';
import { Amenity } from '../models/hotel/Amenity';
import { Hotel } from '../models/hotel/Hotel';
import HotelBookingTypeModel from '../models/hotel/HotelBookingTypeModel';
import HotelExpandedQueryModel from '../models/hotel/HotelExpandedQueryModel';
import HotelManagementQueryModel from '../models/hotel/HotelManagementQueryModel';
import ActivityFormHelperService from '../services/Activities/ActivityFormHelperService';
import CompanyListService from '../services/CRM/Company/CompanyListService';
import CrmFormHelperService from '../services/CRM/CrmFormHelperService';
import HotelListService from '../services/CRM/Hotel/HotelListService';
import UserListService from '../services/SelectList/UserListService';
import { stringIsNullOrEmpty } from '../utils/helpers/stringHelpers';
import UtilityStore from './utilityStore';

class CrmStore {
  constructor(utilStore: UtilityStore) {
    makeObservable(this);
    this.utilStore = utilStore;
    this.Hotels = observable.array(new Array<Hotel>());
    this.Companies = new Array<CompanyModel>() as IObservableArray;
    this.Amenities = observable.array(new Array<Amenity>());
    this.BookingTypes = observable.array(new Array<HotelBookingTypeModel>());
    this.AllUsersList = observable.array(new Array<DropdownItem>());
    this.GroupUserList = observable.array(new Array<DropdownItem>());
    this.PreferredPaymentMethods = observable.array(new Array<FormOfPaymentModel>());
    this.DailyHotelRates = observable.array(new Array<DailyHotelRateModel>());
    this.ReportingRequirements = observable.array(new Array<ReportingRequirement>());
    this.RoomTypes = observable.array(new Array<string>());
    this.LoyaltyAccountTypes = observable.array(new Array<LoyaltyAccount>());
    this.Cities = observable.array(new Array<string>());
    this.CitiesWithProvince = observable.array(new Array<string>());
    this.CompanyQueryModel = new CompanyQueryModel();
    this.HotelQueryModel = new HotelManagementQueryModel();
    this.GroupQueryModel = new GroupQueryModel();
    this.ActivityFormSearch = new ActivityFormHelperResponseModel();
    this.HotelExpandedQueryModel = new HotelExpandedQueryModel();
    this.HotelSelection = new Selection<Hotel>({
      onSelectionChanged: () => {
        const selectionCount = this.HotelSelection.getSelectedCount();
        let details = '';
        switch (selectionCount) {
          case 0:
            details = 'No hotels selected';
            break;
          case 1:
            details = '1 hotel selected: ' + this.HotelSelection.getSelection()[0].Name;
            break;
          default:
            details = `${selectionCount} hotels selected`;
            break;
        }
        this.HotelSelectionDetails = details;
        this.HotelSelectionCount = selectionCount;
      }
    });
    this.AllowHotelSelection = new ShowActiveModel(false);
  }

  @observable
  public Amenities: IObservableArray<Amenity>;

  @observable
  public BookingTypes: IObservableArray<HotelBookingTypeModel>;

  @observable
  public PreferredPaymentMethods: IObservableArray<FormOfPaymentModel>;

  @observable
  public DailyHotelRates: IObservableArray<DailyHotelRateModel>;
  @observable
  public ReportingRequirements: IObservableArray<ReportingRequirement>;
  @observable
  public RoomTypes: IObservableArray<string>;

  @observable
  public LoyaltyAccountTypes: IObservableArray<LoyaltyAccount>;

  @observable
  public Cities: IObservableArray<string>;

  @observable
  public CitiesWithProvince: IObservableArray<string>;

  @observable
  public Hotels: IObservableArray<Hotel>;

  @observable
  public AllUsersList: IObservableArray<DropdownItem>;
  @observable
  public GroupUserList: IObservableArray<DropdownItem>;
  @observable
  public ActivityFormSearch: ActivityFormHelperResponseModel;
  @observable
  public Companies: IObservableArray<CompanyModel>;

  @observable
  // @ts-expect-error added by automation
  public HotelSelectionDetails: string;

  @observable
  public HotelSelectionCount: number = 0;

  @observable
  public CustomPayment: boolean = false;
  @observable
  public RemoveCustomPayment: boolean = false;

  public HotelSelection: Selection<Hotel>;

  public AllowHotelSelection: ShowActiveModel;

  @computed
  public get CompaniesFiltered() {
    var filtered = this.Companies as Array<CompanyModel>;
    if (!stringIsNullOrEmpty(this.CompanyQueryModel.City)) {
      filtered = this.Companies.filter((x) => this.compareStrings(this.CompanyQueryModel.City, 'City', x.Address));
    }
    if (!stringIsNullOrEmpty(this.CompanyQueryModel.Filter)) {
      filtered = filtered.filter((x) => this.compareStrings(this.CompanyQueryModel.Filter, 'Name', x));
    }

    return filtered;
  }

  private compareStrings(toCompare: string, propertyName: string, obj: any): boolean {
    return (
      stringIsNullOrEmpty(toCompare) ||
      (!stringIsNullOrEmpty(obj[propertyName]) &&
        obj[propertyName].toLowerCase().indexOf(toCompare.toLowerCase()) !== -1)
    );
  }

  @observable
  // @ts-expect-error added by automation
  public CompanyActivitiesHub: CompanyActivitiesHub;

  @observable
  // @ts-expect-error added by automation
  public HotelActivitiesHub: HotelActivitiesHub;

  public CompanyQueryModel: CompanyQueryModel;

  public GroupQueryModel: GroupQueryModel;

  public HotelQueryModel: HotelManagementQueryModel;

  public HotelExpandedQueryModel: HotelExpandedQueryModel;

  private utilStore: UtilityStore;

  @action
  public PopulateSearchCriteria = async () => {
    const service = new ActivityFormHelperService();
    service.getCriteria(this);
  };

  @action
  public ConnectCompanyActivitiesHub = (companyId: number) => {
    this.CompanyActivitiesHub = new CompanyActivitiesHub(companyId);
  };

  @action
  public ConnectHotelActivitiesHub = (hotelId: number) => {
    this.HotelActivitiesHub = new HotelActivitiesHub(hotelId);
  };

  @action
  public LoadFormData = async () => {
    const service = new CrmFormHelperService();
    await service.loadFormData(this);
  };

  @action
  public LoadRoomTypes = async () => {
    const service = new CrmFormHelperService();
    await service.loadRoomTypes(this);
  };

  @action
  public LoadHotels = async () => {
    this.utilStore.spinnerState.open();
    const service = new HotelListService();
    await service.getHotels(this);
    this.utilStore.spinnerState.close();
  };
  @action
  public LoadHotelsExpandedSearch = async () => {
    this.utilStore.spinnerState.open();
    const service = new HotelListService();
    await service.getHotelExpandedSearch(this);
    this.utilStore.spinnerState.close();
  };

  @action
  public async LoadUsersDropdownList() {
    const userListService = new UserListService();
    const list = await userListService.getUsers();
    this.AllUsersList.replace(list);
  }

  @action
  public async LoadGroupUsersDropdownList() {
    const userListService = new UserListService();
    const list = await userListService.getGroupUsers();
    this.GroupUserList.replace(list);
  }

  @action
  public LoadCompanies = async () => {
    this.utilStore.spinnerState.open();
    const service = new CompanyListService();
    await service.getCompanies(this);
    this.utilStore.spinnerState.close();
  };

  @action
  public SubmitCustomPayment = () => {
    this.CustomPayment = true;
  };
  @action
  public RemovePayment = () => {
    this.RemoveCustomPayment = true;
  };

  @action
  // @ts-expect-error added by automation
  public AddRoomType = (option) => {
    this.RoomTypes.push(option);
  };

  @action ResetCustomPayment = () => {
    this.CustomPayment = false;
  };
}
export default CrmStore;
