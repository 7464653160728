import { computed, observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

export class LoyaltyAccount extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public AccountNumber: string;
  @observable
  // @ts-expect-error added by automation
  public GdsCode: string;
  @observable
  // @ts-expect-error added by automation
  public Type: string;

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed
  public get DisplayString() {
    return `${this.Type}: ${this.GdsCode} - ${this.AccountNumber}`;
  }
}
