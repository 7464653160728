import { useAuth } from '@cksoftware/react-base';
import { useEffect, useState } from 'react';
import { AllAccessRoles } from 'src/constants/applicationRoles';
import { NavItem } from './types/NavItem';

export const useShouldRenderNavItem = (item: NavItem) => {
  const auth = useAuth();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(shouldRenderItem(item));
  }, [auth.user?.Roles, item]);

  const shouldRenderItem = (item: NavItem): boolean => {
    if (item.text === undefined) {
      return false;
    }
    if (!item.allowedRoles) {
      return true;
    }
    if (item.allowedRoles.length == 0 && auth.isAuthenticated) {
      return true;
    }
    if (item.allowedRoles && !auth.isAuthenticated) {
      return false;
    }

    if (auth.user && auth.user.Roles.some((val) => AllAccessRoles.includes(val))) {
      return true;
    }
    var toReturn = item.allowedRoles.some((allowedRole) => auth.user && auth.user.Roles.includes(allowedRole));
    return toReturn;
  };

  return {
    shouldRender
  };
};
