import { observable, makeObservable } from 'mobx';
import { IProvinceState } from '../common/Interfaces/IProvinceState';
import EntityFormModel from '../forms/EntityFormModel';

class NearbyCityModel extends EntityFormModel implements IProvinceState {
  @observable
  // @ts-expect-error added by automation
  public CityName: string;

  @observable
  // @ts-expect-error added by automation
  public ProvinceState: string;

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}

export default NearbyCityModel;
