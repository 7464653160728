import CallOutState from '../models/common/callout/CallOutState';
import ModalState from '../models/modal/ModalState';
import AppFeatureFlags from '../models/settings/AppFeatureFlags';
import SpinnerOverlayState from '../models/spinner/SpinnerOverlayState';
import ToastState from '../models/toast/ToastState';
import AppSettingsService from '../services/AppSettings/AppSettingsService';

class UtilityStore {
  constructor() {
    this.modalState = new ModalState();
    this.calloutState = new CallOutState();
    this.toastState = new ToastState();
    this.spinnerState = new SpinnerOverlayState();
    // @ts-expect-error added by automation
    this.featureFlags = null;

    AppSettingsService.getAppSettings().then((x) => {
      this.featureFlags = x.Features;
    });
  }

  public modalState: ModalState;
  public calloutState: CallOutState;
  public toastState: ToastState;
  public spinnerState: SpinnerOverlayState;
  public featureFlags: AppFeatureFlags;
}
export default UtilityStore;
