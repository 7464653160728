import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';

interface ToolTipIconProps extends Omit<IconButtonProps, 'icon' | 'aria-label'> {
  icon: IconDefinition;
  toolTip: string;
}

export default forwardRef<HTMLButtonElement, ToolTipIconProps>(({ icon, toolTip, ...props }, ref) => {
  return (
    <Tooltip label={toolTip}>
      <IconButton
        ref={ref}
        variant='ghost'
        cursor={'pointer'}
        color={'blue.100'}
        icon={<FontAwesomeIcon icon={icon} />}
        aria-label={toolTip}
        //makes you treat the buttons like elements the size of icon
        minW={'fit-content'}
        minH={'fit-content'}
        border={'1px'}
        width={'fit-content'}
        height={'fit-content'}
        p={1}
        {...props}
      />
    </Tooltip>
  );
});
