import ActivityNoteModel from 'src/models/activities/ActivityNoteModel';

//these are used to display info based on the type i.e. Tooltip values in
export enum ModelType {
  Inbound = 'inbound',
  Outbound = 'outbound',
  FollowUp = 'follow up',
  Note = 'note',
  File = 'file',
  Mir = 'mir file'
}

export interface ExtractedElements {
  width?: string;
  modelType: ModelType;
  item: ActivityNoteModel;
  isPdf?: boolean;
}
